import React from "react"
import { HeroSmall } from "src/templates"
import { BenefitsList } from "src/components/common/BenefitsList"
import { Timeline } from "src/components/common/Timeline"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Imports para los contenidos
import { getContent } from 'src/queries';

export default function SobreCumplo() {

 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))

 const NewlineText = (text) => {
  return text.split('\n').map((str, index) => <div key={index}>{str}<br/></div>);
 }

 const heroNosotrosBannerResults = getContent("hero-nosotros-banner", process.env.GATSBY_REACT_APP_REGION)
 const nosotrosFeaturesResults = getContent("nosotros-features", process.env.GATSBY_REACT_APP_REGION)
 const whatMakesUsContentAreaResults = getContent("what-makes-us-content-area", process.env.GATSBY_REACT_APP_REGION)
 const timelineFeaturesResults = getContent("timeline-features", process.env.GATSBY_REACT_APP_REGION)

 return (
  <>
   {/* Bloque de video o imagen principal */}
   <HeroSmall
    title={heroNosotrosBannerResults?.title}
    body={heroNosotrosBannerResults?.body?.internal?.content}
    backgroundDesktop={heroNosotrosBannerResults?.backgroundDesktop?.file?.url}
    backgroundMobile={heroNosotrosBannerResults?.backgroundMobile?.file?.url}
   />
   {/* Bloque de pasos */}
   <Box style={{ padding: "40px 0px" }}>
    <Container>
     <Box sx={{ maxWidth: "1100px", margin: "0 auto", display: "flex", flexDirection: md ? "column" : "row", columnGap: 3, rowGap: 3, alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ width: "20%", textAlign: "center" }}>
       <Box component="img" src={nosotrosFeaturesResults?.features[0]?.image?.file?.url} />
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", maxWidth: "80%", rowGap: md && 3, textAlign: md && 'center' }}>
       <Typography variant="h5">{NewlineText(nosotrosFeaturesResults?.features[0]?.body?.internal?.content)}</Typography>
      </Box>
     </Box>
    </Container>
   </Box>
   {/* Bloque de nuestro propósito */}
   <BenefitsList
    title={whatMakesUsContentAreaResults?.title}
    items={whatMakesUsContentAreaResults?.icons}
    option="variant2"
   />
   {/* Bloque de timeline */}
   <Box style={{ marginTop: "40px", background: `${theme.palette.primary.main}20`, padding: "96px 0px 40px"}}>
    <Container>
     <Timeline
      title={timelineFeaturesResults?.title}
      items={timelineFeaturesResults?.features}
     />
    </Container>
   </Box>
  </>
 )
}
