import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Flecha1 from "src/assets/svg/flecha-historia1.svg";
import Flecha2 from "src/assets/svg/flecha-historia2.svg";
import Flecha3 from "src/assets/svg/flecha-historia3.svg";

const Timeline = ({title, items}) => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))
   
 const styles = {
  yearContainer: {
   width: "85px",
   height: "85px",
   background: theme.palette.primary.main,
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "center",
   borderRadius: "100% 100% 0% 100%",
  },
  timelineItem: {
   display: "flex",
   flexDirection: md ? 'column' : "row",
   '&:nth-of-type(1)': {
    '&:before': {
     width: 45,
     display: md ? 'none' : 'block',
     position: 'absolute',
     content: "' '",
     backgroundImage: `url(${Flecha1})`,
     backgroundPosition: 'center',
     backgroundRepeat: 'no-repeat',
     backgroundSize: 'contain',
     height: "320px",
     zIndex: -1,
     marginLeft: "-45px",
     marginTop: "50px",
    }
   },
   '&:before': {
    width: 90,
    display: md ? 'none' : 'block',
    position: 'absolute',
    content: "' '",
    backgroundImage: `url(${Flecha2})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: "360px",
    zIndex: -1,
    marginLeft: "-45px",
    marginTop: "50px",
   },
   '&:nth-of-type(9)': {
    '&:before': {
     width: 128,
     display: md ? 'none' : 'block',
     position: 'absolute',
     content: "' '",
     backgroundImage: `url(${Flecha3})`,
     backgroundPosition: 'center',
     backgroundRepeat: 'no-repeat',
     backgroundSize: 'contain',
     height: "320px",
     zIndex: -1,
     marginLeft: "-45px",
     marginTop: "95px",
    },
   },
   '&:nth-of-type(10)': {
    marginLeft: "60px",
    marginTop: "50px",
    '&:before': {
     display: 'none'
    },
    '& img': {
     width: 300,
     height: 300,
     marginLeft: md ? "-75px" : "-148px",
     zIndex: -1,
     marginRight: md ? 0 : "48px",
    },
    '& p': {
     width: 300,
     height: md ? 0 : "auto",
     zIndex: -1,
    },
   }
  },
  timelineImage: {
   width: 140,
   height: 140,
   marginLeft: md ? 0 : "12px",
   marginRight: md ? 0 : "30px",
   marginTop: md ? '-50px' : 3,
  },
  imageAndTextContainer: {
   display: 'flex',
   flexDirection: md ? 'column' : 'row',
   alignItems: "center",
  },
 }
 
 return (
  <>
   <Typography variant="h3" align="center" color="primary.dark">{title}</Typography>
   <Box sx={{ paddingBottom: 12}}/>
   {/* Contenedor */}
   <Box sx={{ maxWidth: 712, margin: "0 auto", display: "flex", flexDirection: "column", rowGap: md ? 12 : 17 }}>
    {items.map((item, key, array) => (
     <Box key={key} sx={styles.timelineItem}> 
      <Box sx={styles.yearContainer}>
       <Box sx={{ width: 85 }}>
        <Typography align="center" variant="h2" color={theme.palette.text.secondary}>
         {item?.description}
        </Typography>
       </Box>
      </Box>
      <Box sx={styles.imageAndTextContainer}>
       <Box component="img" sx={styles.timelineImage} src={item?.image?.file?.url}/>
       <Box sx={{ marginTop: 3 }}>
        <Typography variant={array && array?.length - 1 === key ? "h1" : "subtitle1"} display="inline" color="primary.main">{item?.title}</Typography>
        <Typography color="text.primary" variant={array & array?.length - 1 === key ? "h2" : "body1"} display="block"> {item?.childrenContentfulFeatureBodyTextNode[0]?.body}</Typography>
       </Box>
      </Box>
     </Box>
    ))}
   </Box>
  </>
 )}

export { Timeline }
